/**** POP UP DE CARREGAMENTO - PRÓXIMA ETAPA FINAL *****/
#loading {
    display: none !important;
}

.logo {
    margin: auto auto;
    font-weight: 700;
}

.loading_spinner {
    display: none;
    border: 6px solid #ffffff;
    border-top: 6px solid #0095ff;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
    margin-top: 50px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.compra_aprovada {
    display: flex;
    margin-top: 80px !important;
    margin: auto auto;
    width:650px;
    gap: 25px;
    flex-direction: column;
    align-content: center;
}
.compra_aprovada img{
    margin: auto auto;
    display: block;
}
.compra_aprovada .titulo {
    background: #FFFFFF;
    border-top: 5px solid #00C777;
    box-shadow: 0px 4px 14px rgba(111, 127, 136, 0.12);
    border-radius: 8px;
    padding: 50px 25px;
}

.compra_aprovada .titulo h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #001A21;
}

.compra_aprovada .titulo p {
    margin: auto auto;
    width: 550px;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    letter-spacing: 0.5px;
    color: #475C64;
}
.informacao {
    padding: 25px;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(111, 127, 136, 0.12);
    border-radius: 8px;
    display: flex;
    align-items: center;
    text-align: left;
    color: #001A21;
    font-weight: 600;
    font-size: 14px;
}

.btn_acessar {
    width: 320px;
    height: 44px;
    font-weight: 500;
    letter-spacing: 0.5px;
    background: #0095FF;
    border-radius: 70px;
    color: white;
    font-size: 14px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.btn_acessar:hover {
    background: #027dd5;
    cursor: pointer;
}

.informacao2 {
    padding: 25px;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(111, 127, 136, 0.12);
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    color: #001A21;
    font-size: 13px;
}
.informacao2 img{
    margin: 0 !important;
}
.data_expiracao {
    width: 100%;
    display: flex;
}

.texto_cartao {
    margin: auto auto;
    width: 400px;
}

.texto_cartao img {
    display: block;
    margin: auto auto;
}

.texto_cartao h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5px;
    color: #001A21;
    margin-top: 0px;
}

.texto_cartao p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    letter-spacing: 0.5px;
    color: #475C64;
    margin-bottom: 40px;
}

#etapa03 {
    margin-bottom: 0px !important;
}

.bloco-esq {
    box-shadow: none;
}

.processar_cartao {
    border-top: #FF4D4D 5px solid;
    border-radius: 12px;
    margin-top: 60px !important;
    background: white;
    width: 500px !important;
}

.estrutura-pc-inputs {
    width: 472px !important;
}

.data_expiracao input {
    width: 90% !important;
}

.resumo {
    padding: 80px 20px 80px 20px;
    width: 50%;
    font-size: 18px;
    border-radius: 8px;
    margin: auto auto;
    background-color: white;
    box-shadow: 0px 10px 10px 10px #00000003;
}

.support-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    padding: 10px 24px;
    border-radius: 5px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    transition: background-color 0.3s, transform 0.3s;
}

.support-button:hover {
    background-color: #45a049;
    transform: scale(1.05);
}

.processar_cartao {
    margin: auto auto;
    display: block;
    border-radius: 20px;
    width: 380px;
    padding: 20px;
}

#etapa03 {
    opacity: 1;
    border-radius: 10px;
    margin-bottom: 30px;
}

#resumo {
    opacity: 1;
}

.estrutura-pc-inputs {
    width: 350px;
}

.botao-form-desativado {
    background-color: #e7e7e7 !important;
}

.btn_opcao {
    cursor: pointer !important;
}

.span_error {
    text-align: left;
}

/****** Ajustes necessários dos campos ******/
.bloco_esq {
    width: 100%;
    order: 1;
    border: none;
    /* box-shadow: 0px 4px 14px rgba(111, 127, 136, 0.12); */
    border-radius: 8px;
    margin-bottom: 0px;
    background: #FFFFFF;
    /* padding: 10px; */
}

.data_expiracao {
    width: 100%;
    display: flex;
}

.estrutura_pc_inputs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}

/************************ Estilo Mobile **********************/
@media screen and (min-device-width: 300px) and (max-device-width: 768px) {

    .texto_cartao {
        width: 100%;
    }

    .estrutura-pc-inputs {
        width: 300px !important;
    }

    .processar_cartao {
        margin-top: 40px !important;
        width: 300px !important;
    }

    .compra_aprovada {
        margin-top: 40px !important;
        width: 300px !important;
    }

    .compra_aprovada .titulo p {
        width: 100%;
    }

    .informacao {
        flex-direction: column !important;
    }

    .informacao2 {
        text-align: left !important;
    }

    .btn_acessar {
        width: 100%;
    }

}