
.compra_aprovada{
  display: flex;
  margin-top: 80px !important;
  margin: auto auto;
  width: 749px;
  flex-direction: column;
  align-content: center;
}
.titulo_pix img{
  margin: auto auto;
  display: block;
}
.titulo_pix{
  background: #FFFFFF;
  border-top: 5px solid #0095FF;
  box-shadow: 0px 4px 14px rgba(111, 127, 136, 0.12);
  border-radius: 8px;
  padding: 50px 25px;
}
.titulo_pix h1{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
  color: #001A21;
}
.titulo_pix p{
  margin: auto auto;
  width: 597px;
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 160%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #475C64; 
}
.titulo_pix span{
  color:#0095FF;
}
.codigo_pix{
  display: flex !important;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 12px;
  gap: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}
.codigo_pix img{
  width: 150px;
  margin: 0;
}
.codigo_pix p{
  font-size: 12px !important;
  margin-bottom: 10px !important;
  letter-spacing: 0.5px !important;
  color: #000000 !important;
}
.informacoes_adicionais{
  border-left: 1px solid #AAB8C2;
  padding-left: 20px;
}
.informacoes_adicionais h3{
  text-align: left;
  font-family: 'Poppins' !important;
  font-size: 15px !important;
  color: #394F57 !important;
}
.informacoes_adicionais p{
  margin-bottom: 2px !important;
  text-align: left !important;
  width: 100% !important;
  color: #394F57 !important;
  font-family: 'Poppins' !important;
  font-size: 14px !important;
  font-style: normal;
}
.area_copiar{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.caixa_codigo{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 550px;
  height: 38px;
  border-radius: 40px;
  background: #E9E9E9;
}
.caixa_codigo p{
  white-space: nowrap; /* Impede quebras de linha */
  overflow: hidden; /* Esconde o conteúdo excedente */
  text-overflow: ellipsis; /* Adiciona os três pontos no final */
  padding-left: 10px;
}
.btn_copiar{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  width: 350px;
  height: 38px;
  background: #3CA4FF;
  color: white;
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  font-weight: 500;
  font-size: 16px !important;
}
.btn_copiar:hover{
  background: #186cb5;
  cursor: pointer;
}
.btn_finalizar{
  margin-top: 30px !important;
  margin: auto auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  width: 266px;
  height: 38px;
  border: 2px solid #3CA4FF;
  color: #3CA4FF;
  border-radius: 40px;
  font-weight: 500;
  font-size: 16px !important;
  cursor: pointer;
}
.btn_finalizar:hover{
  color: #fff;
  background:  #3CA4FF;
}
.informacao{
  padding: 25px;
  background: #FFFFFF;
  box-shadow: 0px 4px 14px rgba(111, 127, 136, 0.12);
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  color: #001A21;
  font-size: 13px;
  margin-top: 25px;
  margin-bottom: 100px;
}
#compra_aprovada{
    display: none;
}
#compra_aprovada .titulo {
    background: #FFFFFF;
    border-top: 5px solid #00C777;
    box-shadow: 0px 4px 14px rgba(111, 127, 136, 0.12);
    border-radius: 8px;
    padding: 50px 25px;
}
#validando_compra{
    display: none;
}
#validando_compra .titulo{
  border-top: 5px solid #FF4D4D !important;
  border: 2px solid #FF4D4D;
}

/************************ Estilo Mobile **********************/
@media screen and (min-device-width: 300px) and (max-device-width: 768px) {
  .compra_aprovada{
    width:300px;
    margin-top: 40px !important;
  }
  .titulo_pix {
    padding: 25px 15px;
  }
  .titulo_pix p {
    width: 100%;
  }
  .area_copiar {
    flex-direction: column !important;
  }
  .caixa_codigo {
    flex-direction: column;
    width: 250px;
    height: auto;
    margin-top: 20px;
    border-radius: 8px;
  }
  .caixa_codigo p{
    width: 230px !important;
    font-size: 13px !important;
    padding-left: 0px !important;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .codigo_pix{
    gap: 0;
    flex-direction: column;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .informacoes_adicionais {
    border-left: none;
    padding-left: 20px;
  }
  .btn_copiar{
    width: 250px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 0px;
  }
  .informacao p{
    text-align: left !important;
  }
  .btn_finalizar{
    width: auto;
  }


}