@media (max-width: 600px)
{
  
/**** PARTE DO CONTADOR *****/
.container-contador {
  width: 380px;
}
.coluna2{
  display: flex !important;
  flex-direction: row !important;
  gap: 10px;
}
.botao-form-alterar{
  width: 270px;
}
.contador{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #102C56;
  text-align: center;
  color: white;
  padding: 10px;
  font-size: 18px;
  font-weight: 400;
}
.contador .destaque{
  margin-left:10px;
  color: white;
  font-weight: 500;
  border-radius: 20px;
  border: solid 1px white;
  padding: 2px 20px 2px 20px;
}
.logo img{
  width: 260px;
}
.pagamento img{
  width: 94px;
}
.container{
  display: block;
  margin: auto auto;
  width: 320px;
}
#primeira_etapa p{
  font-size: 14px;
}

/**** PARTE DO CHECKOUT *****/
.estrutura-check-pc {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  width: 100%;
  margin: 25px 0px 20px 0px;
  align-items: center;
  gap: 0px;
}

.estrutura-pc-inputs {
  width: 279px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}
.segundatela .estrutura-pc-inputs{
  width: 300px;
}

.etapa-check {
  padding-top: 10px;
  padding-left: 12px;
  padding-right: 12px;
  
}

.group-input-pc {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
}

.bloco-esq{
  padding: 0;
  padding-bottom: 10px;
}
#etapa01 {
  width: 100% !important;
  margin-bottom: 20px;
}
#etapa02 {
  width: 100% !important;
  margin-bottom: 20px;
}
#etapa03 {
  width: 100% !important;
  margin-bottom: 20px;
}
.colunas{
 width: 100%;
}
.paragrafo {
  width: 100%;
}
.botao-form {
  width: 270px;
}

.imagem_produto {
  display: flex;
  gap: 20px;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  margin-left: 9px;
}
.imagem_produto img {
  width: 100%;
}


}
